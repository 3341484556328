import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useMemberStore } from '@/stores/memberStore'

export function useMemberRecordComplete() {
  const { memberRecord } = storeToRefs(useMemberStore())

  const hasCompleteMemberRecord = computed(() => {
    if (!memberRecord.value) return false

    return (
      memberRecord.value?.referral &&
      memberRecord.value?.termsAgreed &&
      memberRecord.value?.preferredName &&
      memberRecord.value?.lastName &&
      memberRecord.value?.email
    )
  })

  return { hasCompleteMemberRecord }
}
