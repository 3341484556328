import { useCurrentUser } from 'vuefire'
import { computed } from 'vue'

export function useAwakenRole() {
  const user = useCurrentUser()

  const hasAwakenRole = computed(async () => {
    if (user.value) {
      await user.value.getIdToken(true)
      const tokenResult = await user.value?.getIdTokenResult()
      return (tokenResult?.claims?.permits as string[] | undefined)?.includes('AWAKEN') ?? false
    }
    return false
  })

  return { hasAwakenRole }
}
