<script setup lang="ts"></script>
<template>
  <CalloutBlock title="Environmental Stewardship">
    <template #visual>
      <IconLandscape></IconLandscape>
    </template>
    <template #statement
      >We are committed to protecting our planet's delicate balance by reducing our
      impact.</template
    >
    <template #points
      ><CalloutPoint
        title="Fully Carbon Negative"
        statement="We generate 80% more energy via solar than we consume."
      ></CalloutPoint>
      <CalloutPoint
        title="Regenerative Cultivation"
        statement="All byproducts are returned to the soil as organic fertilizer, eliminating waste and reliance on external resources.  "
      ></CalloutPoint>
      <CalloutPoint
        title="Responsible Packaging"
        statement="Our shipping approach uses only essential packaging to minimize waste. All paper-based packaging is 100%
      curbside recyclable, biodegradable, and compostable. Glass and silicone components are recyclable."
      ></CalloutPoint>
    </template>
  </CalloutBlock>
</template>
<style scoped></style>
