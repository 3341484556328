import { analytics, firestore } from '@/firebase'
import { setUserId } from 'firebase/analytics'
import { collection, doc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useCurrentUser, useDocument } from 'vuefire'
import * as Sentry from '@sentry/browser'

const db = firestore

export const useMemberStore = defineStore('member', () => {
  const member = useCurrentUser()

  const memberRecordRef = computed(() => {
    return member.value ? doc(collection(db, 'members'), member.value.uid) : null
  })

  const memberRecord = ref()
  useDocument(memberRecordRef, { target: memberRecord })

  watch(
    member,
    (newMember) => {
      if (newMember && newMember.uid) {
        setUserId(analytics, newMember.uid)
        Sentry.setUser({ id: newMember.uid })
      }
    },
    { immediate: true }
  )

  watch(
    memberRecord,
    (newRecord) => {
      if (newRecord) {
        console.log('memberStore - watch memberRecord - New record.')
      } else {
        console.log('memberStore - watch memberRecord - No document found or permission denied.')
      }
    },
    { immediate: true }
  )

  return {
    member,
    memberRecord,
    memberRecordRef,
  }
})
