import { initializeApp } from 'firebase/app'
import { getFunctions } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
//import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'

const firebaseConfig = {
  apiKey: 'AIzaSyCy1Wr-KwyCHXqq2LX2A6ZxJiE_aOsFe-E',
  authDomain: 'method-foundation.firebaseapp.com',
  projectId: 'method-foundation',
  storageBucket: 'method-foundation.appspot.com',
  messagingSenderId: '424782139692',
  appId: '1:424782139692:web:dd1db2b80214109bd7dc89',
}

const firebaseApp = initializeApp(firebaseConfig)
const functions = getFunctions(firebaseApp)
const analytics = getAnalytics(firebaseApp)
const firestore = getFirestore()

//connectFunctionsEmulator(functions, "localhost", 5001);

/* initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider('6Ldvu3sqAAAAAGKjG0voXX7ZQdTTHHGIuMDIJPs2'),
  isTokenAutoRefreshEnabled: true,
}) */

export { firebaseApp, firestore, functions, analytics }
